import * as React from "react"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LayoutContainer from "../components/layout-container";
import { FaExclamationTriangle } from "@react-icons/all-files/fa/FaExclamationTriangle";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -100%)',
    textAlign: 'center'
  },
  svg: {
    fontSize: '5em'
  }
});

const NotFound = () => {
  const { t } = useTranslation("404");
  const classes = useStyles();

  return (
    <Layout>
      <Seo title="404" />
      <LayoutContainer>
        <div className={classes.container}>
          <h1>{t("notFound")}</h1>
          <FaExclamationTriangle className={classes.svg}/>
        </div>
      </LayoutContainer>
    </Layout>
  )
}

export default NotFound
